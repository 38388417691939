import React from 'react';

const Welcome = () => {
    return (
        <div className='Welcome'>
            <h3>Ethan Hasbrouck</h3>
            <h1>Full Stack Developer</h1>
        </div>
    );
}

export default Welcome;