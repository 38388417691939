import React from 'react';
import './App.css';

const Footer = () => {

    return  (
        <div className='Footer'>
            <p>&copy; 2022 ETHAN HASBROUCK</p>
        </div>
    );
}

export default Footer;